import React, { useState, useEffect, useContext } from "react";
import { CaseContext } from "../Context/caseContext";
import { FlightContext } from "../Context/flightContext";
import { UiContext } from "../Context/uiContext";
import Table from "../table/table";
import { Button, IconButton, TextField, Tooltip } from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { useNavigate, useParams } from "react-router-dom";
import SearchConfigModal from "../Modals/searchConfigModal";
// const headCells = [
//   // "Flight",
//   "Flight Name",
//   "Pilot Name",
//   "Description",
//   "#Videos",
//   "#Frames",
//   "Date",
//   "Action",
// ];

// function FlightTableRow(props) {

//   const {
//     flightId,
//     flightName,
//     pilotName,
//     flightDescription,
//     flightDate,
//     vidCnt,
//     frameCnt,
//   } = props;

//   return (
//     <TableRow>
//       {/*<TableCell>{flightId}</TableCell>*/}
//       <TableCell>{flightName}</TableCell>
//       <TableCell>{pilotName}</TableCell>
//       <TableCell>{flightDescription}</TableCell>
//       <TableCell>{vidCnt}</TableCell>
//       <TableCell>{frameCnt}</TableCell>
//       <TableCell>{flightDate.toLocaleString()}</TableCell>
//       <TableCell>
//         <Button onClick={() => handleViewClick()} key={flightId}>
//           View
//         </Button>
//       </TableCell>
//     </TableRow>
//   );
// }

export default function FlightTable(props) {
  // const flights = props.flights;
  const analyse = props?.analyse;
  const { sarCase, getCaseByID } = useContext(CaseContext);
  const { updateFlightCtx, flightsByCaseQuery } = useContext(FlightContext);
  const { searchConfigModalOpen, handleSearchConfigModalOpen } =
    useContext(UiContext);
  const [columns, setColumns] = useState([
    { title: "Flight ID", field: "flightId", hidden: true },
    { title: "Flight Name", field: "flightName", editable: "onUpdate" },
    { title: "Pilot Name", field: "pilotName", editable: "onUpdate" },
    { title: "#Videos", field: "vidCnt", editable: "onUpdate" },
    {
      title: "#Frames",
      field: "frameCnt",
      editable: "onUpdate",
    },
    {
      title: "Date",
      field: "flightDate",
      editable: "onUpdate",
    },

    {
      title: "Description",
      field: "flightDescription",
      editable: "onUpdate",
      editComponent: (tableData) => (
        <TextField
          value={tableData.rowData.flightDescription}
          onChange={(e) => tableData.onChange(e.target.value)}
          multiline={true}
          maxRows={5}
        />
      ),
    },
    // {
    //   title: "Analyze",
    //   field: "flightId",
    //   render: (rowData) => (
    //     <>
    //       {analyse && (
    //         <Tooltip title="Analyze">
    //           <IconButton
    //             onClick={(e) => handleAnalyseClick(rowData)}
    //             aria-label="Analyze"
    //           >
    //             <AssessmentIcon />
    //           </IconButton>
    //         </Tooltip>
    //       )}
    //     </>
    //   ),
    // },
  ]);
  const { case_id } = useParams();
  const navigate = useNavigate();

  const handleViewClick = (rowData) => {
    console.log("rowData", rowData);
    updateFlightCtx({
      ...rowData,
    });
    navigate(`/cases/${case_id}/flights/${rowData.flightId}`);
  };

  const handleAnalyseClick = (rowData) => {
    updateFlightCtx({
      ...rowData,
    });
    handleSearchConfigModalOpen();
  };

  const onUpdate = (newData, oldData, tableData, setNewTableData) =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        const dataUpdate = [...tableData];
        const index = oldData.tableData.id;
        dataUpdate[index] = newData;
        setNewTableData([...dataUpdate]);

        resolve();
      }, 1000);
    });

  React.useEffect(() => {}, []);
  return (
    <>
      {searchConfigModalOpen && <SearchConfigModal analyse={analyse} />}
      <Table
        tableId={props?.tableId}
        title={""}
        dataQuery={true}
        data={(query) => flightsByCaseQuery(case_id, query)}
        columns={columns}
        loading={props?.loading}
        onRowUpdate={props?.editable ? onUpdate : null}
        options={{
          toolbar: false,
          sorting: false,
          draggable: false,
          grouping: false,
          search: false,
          paging: true,
          selection: false,
          maxBodyHeight: "500px",
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 100],
          actionsCellStyle: { zIndex: "0", position: "relative" },
          headerStyle: { position: "sticky", top: 0, zIndex: 100 },
        }}
        editable={props?.editable}
        onRowView={handleViewClick}
        actions={[
          {
            icon: AssessmentIcon,
            tooltip: "Analyze",
            onClick: (event, rowData) => handleAnalyseClick(rowData),
          },
        ]}
      />
    </>
  );
}
