import React, { createContext, useReducer, useContext } from "react";

const TableContext = createContext();

const tableReducer = (state, action) => {
  // console.log("Action Received:", action);
  // console.log("Current State:", state);

  switch (action.type) {
    case "UPDATE_TABLE_STATE":
      const updatedState = {
        ...state,
        [action.tableId]: {
          ...state[action.tableId],
          ...action.payload,
        },
      };
      // console.log("Updated State:", updatedState);
      return updatedState;

    default:
      return state;
  }
};

export const TableProvider = ({ children }) => {
  const [state, dispatch] = useReducer(tableReducer, {});

  return (
    <TableContext.Provider value={{ state, dispatch }}>
      {children}
    </TableContext.Provider>
  );
};

export const useTableContext = () => useContext(TableContext);
