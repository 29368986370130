import React from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { useAppContext } from "../lib/contextLib";
import { systemContext, userContext, caseContext, analysisContext } from "../index";
import { Box, Button, Stack } from "@mui/material";
import AnalysisTable from "../Analysis/AnalysisTable";

const testAnalyses = [
  {
    id: 1,
    analysis: "Analysis1",
    access: "Case",
    owner: "Bill",
    dateCreated: new Date(),
  },
];

export default function CreateAnalysisPage() {
  const [analyses, setAnalyses] = React.useState([]);

  React.useEffect(() => {
    if (0 == 1) {
      Promise.all([
        fetch( sysCtx.apiUrl, {
            method: "POST",
            headers: {
              ...authHeaders
            },
            body: JSON.stringify({
              action: "get-analysis-run-configs",
              gid: "1",
              wid: "1",
              ip: "0.0.0.0",
            }),
          }
        ),
      ])
        .then(([resAnalyses]) => Promise.all([resAnalyses.json()]))
        .then(([analyses]) => {
          setAnalyses(analyses);
        });
    } else {
      // MANUALLY SET FOR NOW
      setAnalyses(testAnalyses);
    }
  }, []);

  const [selectedAnalyses, setSelectedAnalyses] = React.useState([]);
  function handleAnalysisChange(IDs) {
    // console.log('In CreateAnalysisPage: ')
    setSelectedAnalyses(IDs);
    // console.log(IDs)
    // console.log('Selected CP IDs:', selectedCPs)
  }

  const executeAnalyses = (event) => {
    // event.preventDefault();

    const form = {
      action: "run-analyses",
      gid: "1",
      wid: "1",
      ip: "0.0.0.0",
      analysis_ids: selectedAnalyses,
    };

    console.log(selectedAnalyses);
    console.log("Analysis IDs: ", selectedAnalyses.toString());
    console.log(form);
    alert(
      "Submitted to Backend:\n" + "Analysis IDs: " + selectedAnalyses.toString()
    );
    alert(JSON.stringify(form));

    fetch( sysCtx.apiUrl, {
        method: "POST",
        headers: {
          ...authHeaders
        },
        body: JSON.stringify(form),
      }
    ).then((response) => {
      console.log(response);
      alert(response);
    });
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
          paddingX: 10,
          paddingTop: 5,
        }}
      >
        <Box>
          <AnalysisTable
            onChange={(ids) => handleAnalysisChange(ids)}
            analyses={analyses}
            tableId={"Table-AnalysisExecute"}
          ></AnalysisTable>
        </Box>
        <br />
        <br />
        <Box>
          <Stack
            direction="row"
            spacing={2}
            useFlexGap
            justifyContent="space-evenly"
          >
            <Button variant="contained" type="submit" onClick={executeAnalyses}>
              Execute Analyses
            </Button>
          </Stack>
        </Box>
      </Box>
    </>
  );
}
