import React, {
  useState,
  useContext,
  createRef,
  useRef,
  useEffect,
} from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { CaseContext } from "../Context/caseContext";
import { UserContext } from "../Context/userContext";
import { ImageContext } from "../Context/imageContext";
import { SearchConfigContext } from "../Context/searchConfigContext";
import { AppContext } from "../Context/appContext";
import { API_URL } from "../API";
import useMinMax from "../Hooks/useMinMax";

import CustomColorModal from "../Components/CustomColorModal";
import CustomColorLite from "../Components/CustomColorLite";
import ColorRange from "../Components/ColorRange";
import ColorScale from "../Components/ColorScale";
import ColorScaleLite from "../Components/ColorScaleLite";


//MUI Components
import {
  Box,
  Grid,
  Button,
  Typography,
  Checkbox,
  Paper,
  TextField,
  Input,
  Container,
} from "@mui/material";


//MUI Icons
import CreateIcon from "@mui/icons-material/Create";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

//NPM Imports

import useEyeDropper from "use-eye-dropper";
import "cropperjs/dist/cropper.css";
import { toast } from "react-toastify";

const testImage =
  "https://padelmagazine.fr/wp-content/uploads/2018/09/photo-1456983933114-c22026990f4b-3.jpeg";
const ACTION = "create-select-case";
const defaultValues = {
  gid: "1",
  wid: "1",
  ip: "0.0.0.0",
  action: ACTION,
};

import { CustomCropper } from "../Components/Cropper/CustomCropper";
import { PreviewResults } from "../Components/Cropper/components/PreviewResults";
import "react-advanced-cropper/dist/style.css";
import "react-advanced-cropper/dist/themes/classic.css";

export default function BuildColorPaletteHSL() {
  const navigate = useNavigate();
  const { authHeaders } = useContext(AppContext);
  const { userCtx } = useContext(UserContext);
  const [sarCase, getCaseByID] = useState(CaseContext);
  const { imageCtx, getImageData, uploadImageForColorPalette } =
    useContext(ImageContext);
  const { createColorPaletteSearchConfig } = useContext(SearchConfigContext);
  const { case_id, image_id } = useParams();

  const { open, close, isSupported } = useEyeDropper();
  const [error, setError] = useState();
  // useEyeDropper will reject/cleanup the open() promise on unmount,
  // so setState never fires when the component is unmounted.
  const pickColor = () => {
    open()
      .then((color) => {
        let rgb = hexToRgb(color.sRGBHex);
        setSelectedRgbColor(`rgb(${rgb.r},${rgb.g},${rgb.b})`);
        setSelectedHexColor(color.sRGBHex);
      })
      .catch((e) => {
        console.log(e);
        // Ensures component is still mounted
        // before calling setState
        if (!e.canceled) toast.error(error.message);
      });
  };

  function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  const [cropData, setCropData] = useState("#");
  const cropperRef = createRef();
  const paletteNameRef = useRef();
  const searchDescriptionInput = useRef();
  const [image, setImage] = useState(testImage);
  const [selectedRgbColor, setSelectedRgbColor] = useState(null);
  const [selectedHexColor, setSelectedHexColor] = useState(null);
  const [paletteArray, setPaletteArray] = useState([]);
  const [isEditingColor, setIsEditingColor] = useState(false);
  const [showCustomColor, setShowCustomColor] = useState(false);
  const [color, setColor] = useState("rgba(255,255,255,1)");
  const [colorIndex, setColorIndex] = useState(null);
  const [currentIndexRgb, setCurrentIndexRgb] = useState(null);
  const [currentIndexRgb2, setCurrentIndexRgb2] = useState(null);
  const [currentIndexHex, setCurrentIndexHex] = useState(null);
  const [checkedItems, setCheckedItems] = useState([]);
  const [colorRangeVisible, setColorRangeVisible] = useState(false);
  const [colorRange, setColorRange] = useState();
  const [createdColorRange, setCreatedColorRange] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [preview, setPreview] = useState();


  React.useEffect(() => {
    setImage(imageCtx.filepath);
  }, [imageCtx.filepath]);

  const extractRGBValues = (rgbString) => {
    const rgbArray = rgbString.match(/\d+/g);
    if (rgbArray && rgbArray.length === 3) {
      return rgbArray.map(Number);
    }
    throw new Error("Invalid RGB string format");
  };

  // Function to calculate base64 string size in bytes
  function validateBase64Size(base64String) {
    const padding = base64String.endsWith("==")
      ? 2
      : base64String.endsWith("=")
      ? 1
      : 0;
    const base64Length = base64String.length;
    const imageSizeInBytes = base64Length * 0.75 - padding;
    // Check if the image exceeds the API gateway's limit
    const apiGatewayLimit = 1800000; // in MB
    if (imageSizeInBytes > apiGatewayLimit) {
      // Handle the error - Image size exceeds the limit
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    console.log(paletteArray);
  }, [paletteArray]);

  const fetchData = async () => {
    try {
      await getCaseByID(case_id);
      await getImageData(image_id);
    } catch (error) {
      toast.error(error.message);
    }
  };

  React.useEffect(() => {
    if (1 == 1) {
      console.log("imageCtx: ", imageCtx);
      if (!sarCase.sarCaseId && !imageCtx.imageId) {
        fetchData();
      }
    } else {
      setImage(imageCtx.filepath);
    }
  }, [sarCase.sarCaseId, imageCtx.imageId]);

  async function removePcnt(objects) {
    if (!Array.isArray(objects)) {
      throw new Error("Input must be an array of objects");
    }

    return objects.map((obj) => {
      const newObj = { ...obj };
      delete newObj.pcnt;
      return newObj;
    });
  }

  const sendKeyColors = async (paramData) => {
    if (checkedElements.length === 0) {
      toast.warning("Select atleast one item");
      return;
    }

    if (!paletteNameRef.current.value) {
      toast.warning("Name is required.");
      return;
    }

    const paletteColorsEdit = await removePcnt(paramData);
    const extractedObjects = paletteColorsEdit.map((input) =>
      !input.isRange
        ? {
            rgb: extractRGBValues(input.rgb),
          }
        : {
            rgb: extractRGBValues(input.rgb),
            rgb2: extractRGBValues(input.rgb2),
          }
    );
   
    try {
      const response = await createColorPaletteSearchConfig({
        paletteName: paletteNameRef.current.value,
        cid: parseInt(case_id),
        rgbList: extractedObjects,
        description: searchDescriptionInput.current.value,
      });
      console.log("createColorPaletteSearchConfig response:", response);
      navigate(`/cases/${case_id}/search-configs`);
    } catch (error) {
      toast.error(error.message);
    }

  };


  const addColorToPalette = (rgbValue, hexValue, isRange = false) => {
    if (!rgbValue) {
      return;
    }
    const removeAlpha = rgbValue.replace(
      /rgba\(([^,]+,[^,]+,[^,]+),[^)]+\)/,
      "rgb($1)"
    );
    setPaletteArray([
      ...paletteArray,
      { rgb: removeAlpha, hex: hexValue, isRange },
    ]);
  };

  const removeColorFromPalette = (index) => {
    const updatedPaletteArray = [...paletteArray];
    updatedPaletteArray.splice(index, 1);
    setPaletteArray(updatedPaletteArray);
  };

  const editColorInPalette = (mapIndex, newRgb, newRgb2) => {
    const updatedPaletteArray = [...paletteArray];
    const removeAlpha = newRgb.replace(
      /rgba\(([^,]+,[^,]+,[^,]+),[^)]+\)/,
      "rgb($1)"
    );
    updatedPaletteArray[mapIndex].rgb = removeAlpha;

    if(newRgb2){
      const removeAlpha2 = newRgb2.replace(
        /rgba\(([^,]+,[^,]+,[^,]+),[^)]+\)/,
        "rgb($1)"
      );
      updatedPaletteArray[mapIndex].rgb2 = removeAlpha2;
    }
    
    setPaletteArray(updatedPaletteArray);
  };

  const onClose = () => {
    setIsEditingColor(!isEditingColor);
  };

  const onClickEditColor = (index, rgb, rgb2, hex) => {
    setColorIndex(index);
    setIsEditingColor(!isEditingColor);
    setCurrentIndexRgb(rgb);
    setCurrentIndexRgb2(rgb2);
    setCurrentIndexHex(hex);
  };

  const addCustomColor = () => {
    setShowCustomColor(!showCustomColor);
  };

  const clearPalette = () => {
    setPaletteArray([]);
  };

  const handleCheckboxChange = (event, index, color) => {
    if (event.target.checked) {
      // Add the index to checkedItems
      setCheckedItems([...checkedItems, color, index]);
      // setPaletteArray([...paletteArray, { rgb: rgbValue, hex: hexValue }]
    } else {
      // Remove the index from checkedItems
      setCheckedItems(checkedItems.filter((item) => item !== index));
    }
  };

  const checkedElements = paletteArray.filter((color, index) =>
    checkedItems.includes(index)
  );

  const checkedRangeColors = checkedElements;

  const toggleColorRange = () => {
    setColorRangeVisible(!colorRangeVisible);
  };

  const onChangeSetColorRange = (value) => {
    setColorRange(value);
  };
  function adjustRGB(startRGB, endRGB, buffer) {
    const adjustedStart = startRGB.map((value) => Math.max(value - buffer, 0));
    const adjustedEnd = endRGB.map((value) => Math.min(value + buffer, 255));

    return [adjustedStart, adjustedEnd];
  }

  const onClickCreateRange = (value, rangeBuffer = 10) => {
    let tempValues = value.map((el) => {
      return {
        ...el,
        rgb: el.rgb.replace(/rgba\(([^,]+,[^,]+,[^,]+),[^)]+\)/, "rgb($1)"),
      };
    });
    const getMinMaxFromRange = useMinMax(tempValues);
    const [adjustedStart, adjustedEnd] = adjustRGB(
      getMinMaxFromRange[0],
      getMinMaxFromRange[1],
      rangeBuffer
    );

    setPaletteArray([
      ...paletteArray,
      {
        rgb: `rgb(${adjustedStart.join(",")})`,
        rgb2: `rgb(${adjustedEnd.join(",")})`,
        hex: "",
        isRange: true,
      },
    ]);
    setCreatedColorRange(value);
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "93vh",
        overflow: "auto",
        paddingX: 10,
        paddingTop: 10,
      }}
    >
      {showCustomColor ? (
        <CustomColorLite
          value={color}
          paletteArray={paletteArray}
          onChange={setColor}
          onClose={addCustomColor}
          addColorToPalette={addColorToPalette}
        />
      ) : null}
      {colorRangeVisible ? (
        <ColorRange
          isVisible={colorRangeVisible}
          onClose={toggleColorRange}
          checkedElements={checkedRangeColors}
          addColorToPalette={addColorToPalette}
          onClickCreateRange={onClickCreateRange}
          onClickEditColor={onClickEditColor}
        />
      ) : null}
      <Grid container spacing={4} sx={{ height: "82vh" }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "82vh !Imporant",
            }}
          >
            <Box>
              <CustomCropper
                imageUrl={image}
                // coordinates={coordinates}
                // setCoordinates={setCoordinates}
                setPreview={setPreview}
                setPaletteArray={setPaletteArray}
                paletteArray={paletteArray}
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>

              {isSupported() ? (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "none",
                    },
                    marginTop: "10px",
                    marginLeft: "10px",
                  }}
                  onClick={pickColor}
                >
                  Pick color
                </Button>
              ) : (
                <span>EyeDropper API not supported in this browser</span>
              )}
            </Box>
          </Box>
          <Paper
            elevation={16}
            sx={{
              padding: 2,
              marginTop: "10px",
              width: "100%",
              height: "50%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                outline: "2px solid black",
              }}
            >
              {preview && <PreviewResults preview={preview} />}

            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                marginLeft: 6,
              }}
            >
              <Box
                sx={{
                  width: 50,
                  height: 50,
                  outline: "2px solid black",
                  marginLeft: "10px",
                  marginY: 5,
                  backgroundColor: `${selectedRgbColor}`,
                }}
              ></Box>
              <Box>
                <Typography sx={{ marginBottom: "5px" }}>
                  {selectedRgbColor}
                </Typography>
                <Typography>{selectedHexColor}</Typography>
              </Box>
              <Box sx={{ marginTop: "auto" }}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "none",
                    },
                    marginBottom: "5px",
                  }}
                  onClick={() =>
                    addColorToPalette(selectedRgbColor, selectedHexColor)
                  }
                >
                  Add to Palette
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper
            elevation={16}
            sx={{
              width: "100%",
              height: "82vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "1.5vw !Important",
            }}
          >
            <Container sx={{ padding: "0 !Important" }}>
              <TextField
                placeholder="Enter Palette Name"
                inputRef={paletteNameRef}
                fullWidth
              />
              <TextField
                id="outlined-basic"
                placeholder="Optional Description"
                inputRef={searchDescriptionInput}
                fullWidth
                multiline
                sx={{
                  marginTop: "1vw",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  maxHeight: "58vh",
                  marginTop: "1vw",
                  overflow: "auto",
                  scrollbarWidth: "auto",
                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                  },
                }}
              >
                {paletteArray.map((color, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "start",
                      paddingLeft: "2vw",
                      paddingRight: "2vw",
                      marginTop: "2px",
                      borderBottom: "2px solid black",
                    }}
                  >
                    <CustomColorModal
                      mapIndex={colorIndex}
                      isEditingColor={isEditingColor}
                      onClose={onClose}
                      onChangeColor={editColorInPalette}
                      currIndexRgb={currentIndexRgb}
                      currIndexRgb2={currentIndexRgb2}
                      currentIndexHex={currentIndexHex}
                    />
                    <Box sx={{ marginRight: 2 }}>
                      <Checkbox
                        onChange={(event) =>
                          handleCheckboxChange(event, index, color)
                        }
                        checked={checkedItems.includes(index)}
                      />
                    </Box>
                    {color?.isRange ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          <div
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            &nbsp; &nbsp;
                            <div
                              style={{
                                width: "35px",
                                height: "35px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                backgroundColor: `${color.rgb}`,
                                border: "2px solid #000",
                              }}
                            ></div>
                            <div
                              style={{
                                width: "35px",
                                height: "35px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                backgroundColor: `${color.rgb2}`,
                                border: "2px solid #000",
                              }}
                            ></div>
                          </div>
                        </div>
                        <Box sx={{ marginLeft: 3 }}>
                          <Typography>{color.rgb},</Typography>
                          <Typography>{color.rgb2}</Typography>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box
                          sx={{
                            width: "40px",
                            height: "40px",
                            outline: "2px solid black",
                            marginBottom: "5px",
                            backgroundColor: `${color.rgb}`,
                          }}
                        ></Box>
                        <Box sx={{ marginLeft: 3 }}>
                          <Typography>{color.rgb}</Typography>
                        </Box>
                      </>
                    )}

                    <Box
                      sx={{
                        marginLeft: "auto",
                        marginBottom: "2px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <CreateIcon
                        onClick={() =>
                          onClickEditColor(
                            index,
                            color.rgb,
                            color.isRange ? color.rgb2 : null,
                            color.hex
                          )
                        }
                      />
                      <DeleteForeverIcon
                        sx={{ marginLeft: 3 }}
                        onClick={() => removeColorFromPalette(index)}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            </Container>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                gap: 1,
              }}
            >
        
              <Button
                variant="contained"
                color="primary"
                sx={{
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "none",
                  },
                  marginBottom: 5,
                }}
                onClick={() => addCustomColor()}
              >
                Add Custom Color
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "none",
                  },
                  marginBottom: 5,
                }}
                onClick={() => toggleColorRange()}
              >
                Add Color Range
              </Button>
              <Button
                onClick={() => sendKeyColors(checkedElements)}
                variant="contained"
                color="primary"
                sx={{
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "none",
                  },
                  marginBottom: 5,
                  // maxHeight: 55,
                }}
              >
                Submit
              </Button>
  
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
