import React, { useState, useEffect, useContext, useMemo } from "react";
import { CaseContext } from "../Context/caseContext";
import { FlightContext } from "../Context/flightContext";
import { FootageContext } from "../Context/footageContext";

import { IconButton, Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate, useParams } from "react-router-dom";
import Table from "../table/table";
import { toast } from "react-toastify";

import placeholderImage from "../Assets/image.png";
import { UiContext } from "../Context/uiContext";

export default function FlightFootageTable(props) {
  const navigate = useNavigate();
  const {
    updateFootageCtx,
    setFootage,
    radioMetricConverters,
    deleteFlightFootage,
    footageByFlightQuery,
    footage,
  } = useContext(FootageContext);

  // const  = props.footage;

  const { case_id, flight_id } = useParams();

  // const [columns, setColumns] = useState([]);
  const [imageStatus, setImageStatus] = useState({});

  const { openInNewTab } = useContext(UiContext);

  useEffect(() => {
    const preloadImages = async (data) => {
      const promises = data.map(
        (row) =>
          new Promise((resolve) => {
            const img = new Image();
            img.src = row.filepath || placeholderImage;
            img.onload = () => resolve({ id: row.frameId, status: "loaded" });
            img.onerror = () => resolve({ id: row.frameId, status: "error" });
          })
      );

      const results = await Promise.all(promises);
      const statusMap = results.reduce((acc, { id, status }) => {
        acc[id] = status;
        return acc;
      }, {});

      setImageStatus(statusMap);
    };

    preloadImages(footage);
  }, [footage]);

  const handleEditClick = (rowData) => {
    updateFootageCtx({
      ...rowData,
      sarCaseId: rowData.sar_case_id,
    });

    navigate(
      `/cases/${rowData.sar_case_id}/flights/${rowData.flightId}/footage/${rowData.frameId}`
    );
  };

  const onUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        const dataUpdate = [...data];
        const index = oldData.tableData.id;
        dataUpdate[index] = newData;
        setData([...dataUpdate]);

        resolve();
      }, 1000);
    });

  const handleDeleteClick = async (oldData) => {
    return new Promise(async (resolve, reject) => {
      try {
        updateFootageCtx({
          ...oldData,
          sarCaseId: oldData.sar_case_id,
        });
        await deleteFlightFootage(oldData.flightId, oldData.frameId);
        let dataUpdate = footage.filter((el) => el.frameId !== oldData.frameId);
        setFootage([...dataUpdate]);
        resolve(true);
      } catch (error) {
        toast.error(error.message);
        resolve(error);
      }
    });
  };

  const onSelectionChange = (rows, rowData) => {
    if (rows.length > 0) {
      let duplicateState = [...props?.footageIds];
      duplicateState = rows.map((el) => el.frameId);
      props?.setFootageIds(duplicateState);
    } else {
      if (rowData) {
        let duplicateState = [...props?.footageIds];
        duplicateState = duplicateState.filter((el) => el !== rowData.frameId);
        props?.setFootageIds(duplicateState);
      } else {
        props?.setFootageIds([]);
      }
    }
  };

  function getImageName(url) {
    // Split the URL by the '/' character
    const parts = url.split('/');
    // Get the last part of the array, which is the image name
    const imageName = parts[parts.length - 1];
    // Remove the timestamp by finding the last occurrence of '-' and taking the substring after it
    const timestampEndIndex = imageName.indexOf('-', imageName.indexOf('-') + 1) + 1;
    const imageNameWithoutTimestamp = imageName.substring(timestampEndIndex);
    return imageNameWithoutTimestamp;
  }

  const columns = useMemo(
    () => [
      {
        title: "View",
        field: "frameId",
        render: (rowData) => (
          <>
            <Tooltip title="View">
              <IconButton
                onClick={(e) => handleEditClick(rowData)}
                aria-label="View"
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
      { title: "Frame ID", field: "frameId", editable: "never" },
      { title: "Flight ID", field: "flightId", editable: "never" },
      { title: "Case ID", field: "sar_case_id", editable: "never" },
      {
        title: "Thumbnail",
        field: "display_filepath",
        editable: "never",
        render: (rowData) => (
          <>
            {imageStatus[rowData.frameId] === "error" ? (
              <Tooltip title="Re-upload">
                <img
                  src={placeholderImage}
                  style={{ maxWidth: 100, maxHeight: 100 }}
                  alt="Display"
                  onClick={() =>
                    openInNewTab(
                      `Cases/${rowData?.sar_case_id}/Flights/${
                        rowData.flightId
                      }/upload/footage?imageName=${getImageName(
                        rowData.filepath
                      )}&frameID=${rowData.frameId}&footageType=${
                        rowData.img_view_type
                      }&converterType=${rowData.radiometric_converter_id}`
                    )
                  }
                />
              </Tooltip>
            ) : (
              // </a>
              <a
                href={rowData.filepath}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={rowData.display_filepath || placeholderImage}
                  style={{ maxWidth: 100, maxHeight: 100 }}
                  alt="Display"
                />
              </a>
            )}
          </>

          // <a href={rowData.filepath} target="_blank">
          //   <img
          //     src={rowData.display_filepath}
          //     style={{ maxWidth: 100, maxHeight: 100 }}
          //   />
          // </a>
        ),
      },
      { title: "video_timestamp", field: "video_timestamp", editable: "never" },
      { title: "latitude", field: "latitude", editable: "never" },
      { title: "longitude", field: "longitude", editable: "never" },
      {
        title: "altitude",
        field: "alt_agl",
        editable: "never",
      },
      { title: "heading", field: "heading", editable: "never" },
      { title: "pitch", field: "pitch", editable: "never" },
      { title: "roll", field: "roll", editable: "never" },
      {
        title: "reftime",
        field: "reftime",
        editable: "never",
        render: (rowData) =>
          (rowData.reftime && rowData.reftime.toLocaleString()) || "N/A",
      },
      {
        title: "img_view_type",
        field: "img_view_type",
        editable: "never",
        render: (rowData) =>
          rowData.img_view_type == 0
            ? `Color`
            : rowData.img_view_type == 1
            ? `Thermal RGB`
            : rowData.img_view_type == 2
            ? `Radiometric`
            : `Other {rowData.img_view_type}`,
      },
      {
        title: "Radiometric converter",
        field: "radiometric_converter_id",
        editable: "never",

        render: (rowData) => {
          const index = radioMetricConverters.findIndex(
            (el) => el.objId === rowData.radiometric_converter_id
          );
          return index !== -1
            ? radioMetricConverters[index]?.dispName
            : rowData.img_view_type === 2
            ? "No Conversion"
            : "N/A";
        },
      },
      {
        title: "match_tag_list",
        field: "match_tag_list",
        editable: "never",
        render: (rowData) =>
          rowData.match_tag_list !== null ? rowData.match_tag_list : "N/A",
      },
    ],
    [imageStatus]
  );

  // useEffect(() => {
  //   setColumns();
  // }, []);

  return (
    <>
      <Table
        // data={footage}
        title={""}
        dataQuery={true}
        data={(query) => footageByFlightQuery(flight_id, query)}
        columns={columns}
        loading={props?.loading}
        onRowUpdate={props?.editable ? onUpdate : null}
        options={props?.options}
        editable={props?.editable}
        onSelectionChange={props?.options?.selection ? onSelectionChange : null}
        deleteable={props?.deleteable}
        onRowDelete={props?.deleteable ? handleDeleteClick : null}
      />
    </>
  );
}
