import React, { useState, useEffect, useContext } from "react";
import {
  systemContext,
  userContext,
  caseContext,
  imageContext,
} from "../index";
import { CaseContext } from "../Context/caseContext";
import { SearchConfigContext } from "../Context/searchConfigContext";
import { ImageContext } from "../Context/imageContext";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Table from "../table/table";
import { toast } from "react-toastify";
const headCells = ["Id", "Search Config Name", "SC Type", "Date", "Action"];

function ImageTableRow(props) {
  const { sarCase, getCaseByID } = useContext(CaseContext);
  const {
    searchConfigs,
    searchConfigCtx,
    updateSearchConfigCtx,
    deleteSearchConfigData,
    getSearchConfigsByCase,
  } = useContext(SearchConfigContext);

  const { scId, sarCaseId, scName, scTypeId, createDate } = props;
  const navigate = useNavigate();

  const handleColorsClick = (event) => {
    updateImageCtx({
      imageId: imageId,
      filepath: filepath,
    });
    if (img_purpose == 0) navigate(`/build-color-palette-rgb`);
    else navigate(`/Images`);
  };

  const handleFacialClick = (event) => {
    updateImageCtx({
      imageId: imageId,
      filepath: filepath,
    });

    navigate(`/FaceDetect/DetectFacesPalette`);
  };

  return (
    <TableRow>
      <TableCell>{scId}</TableCell>
      <TableCell>{scName}</TableCell>
      <TableCell>
        {scTypeId == 0
          ? Colors(RGB)
          : scTypeId == 1
          ? Colors(HSV)
          : scTypeId == 2
          ? Face
          : scTypeId == 3
          ? Object
          : scTypeId == 4
          ? Thermal(RGB)
          : scTypeId == 5
          ? Radiometric
          : Other}
      </TableCell>
      <TableCell>{createDate.toLocaleString()}</TableCell>
      <TableCell>
        <Button onClick={() => handleViewClick()} key={imageId}>
          View
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default function SearchConfigsTable(props) {
  const searchConfigs = props.searchConfigs;
  const { sarCase, getCaseByID } = useContext(CaseContext);
  const {
    updateSearchConfigCtx,
    deleteSearchConfigData,
    getSearchConfigsByCase,
    updateSearchConfig,
  } = useContext(SearchConfigContext);
  const [isLoading, setIsLoading] = useState(false);
  const [val, setVal] = useState({});
  const [data, setData] = useState(searchConfigs);

  const handleSearchConfigDeleteClick = async (rowData) => {
    updateSearchConfigCtx({
      searchConfigId: rowData.searchConfigId,
    });

    console.log("To call sc Context delete function");
    try {
      await deleteSearchConfigData(rowData);
      // await getSearchConfigsByCase(case_id);
    } catch (error) {
      toast.error(error.message);
    }
    console.log("Did call sc Context delete function");
  };
  const [columns, setColumns] = useState();

  const { case_id, analysis_id } = useParams();

  const onUpdate = (newData, oldData, tableData, setNewTableData) =>
    new Promise(async (resolve, reject) => {
      try {
        const dataUpdate = [...tableData];
        const index = dataUpdate.findIndex(
          (x) => x.searchConfigId === oldData.searchConfigId
        );
        dataUpdate[index] = newData;
        setNewTableData(dataUpdate);
        await updateSearchConfig({
          scid: oldData.searchConfigId,
          name: newData.searchConfigName,
          description: newData.description,
        });
        resolve();
      } catch (error) {
        toast.error(error.message);
        reject(error);
      }
    });

  React.useEffect(() => {
    setColumns({
      color_rgb: [
        // { title: "Case Id", field: "sarCaseId" },
        { title: "Config Id", field: "searchConfigId", editable: "never" },
        {
          title: "Config Name",
          field: "searchConfigName",
          editable: "onUpdate",
        },

        {
          title: "Colors (RGB)",
          editable: "never",
          field: "rgb",
          render: (rowData) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {rowData.rgbList.map(function (object, i) {
                  const r = object[0];
                  const g = object[1];
                  const b = object[2];
                  const r2 = object[3];
                  const g2 = object[4];
                  const b2 = object[5];
                  const isRange =
                    !(r2 == 0 && g2 == 0 && b2 == 0) &&
                    !(r2 == r && g2 == g && b2 == b);

                  if (isRange)
                    return (
                      <div
                        key={i}
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        &nbsp; &nbsp;
                        <Tooltip
                          title={`rgb(${r},${g},${b})  -  rgb(${r2},${g2},${b2})`}
                        >
                          <div>
                            <div
                              style={{
                                width: "40px",
                                height: "40px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                backgroundColor: `rgb(${r},${g},${b})`,
                                border: "2px solid #000",
                              }}
                            ></div>
                            <div
                              style={{
                                width: "40px",
                                height: "40px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                backgroundColor: `rgb(${r2},${g2},${b2})`,
                                border: "2px solid #000",
                              }}
                            ></div>
                          </div>
                        </Tooltip>
                      </div>
                    );
                  else
                    return (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        &nbsp; &nbsp;
                        <Tooltip title={`rgb(${r},${g},${b})`}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "40px",
                              height: "40px",
                              marginLeft: "auto",
                              marginRight: "auto",
                              backgroundColor: `rgb(${r},${g},${b})`,
                              border: "2px solid #000",
                            }}
                          />
                        </Tooltip>
                      </div>
                    );
                })}
              </div>
            );
          },
        },
        {
          title: "Description",
          field: "description",
          editable: "onUpdate",
          editComponent: (tableData) => (
            <TextField
              value={tableData.rowData.description}
              onChange={(e) => tableData.onChange(e.target.value)}
              multiline={true}
              maxRows={5}
            />
          ),
        },
      ],
      color_hsv: [
        { title: "Config Id", editable: "never", field: "searchConfigId" },
        {
          title: "Config Name",
          field: "searchConfigName",
          editable: "onUpdate",
        },

        {
          title: "Colors (HSL)",
          field: "hsl",
          editable: "never",
          render: (rowData) => {
            return (
              <div
                style={{
                  display: "flex",
                  note: "hsv is internal name, but data is stored as HSL for frontend usage",
                }}
              >
                {rowData.hsvList.map(function (object, i) {
                  const h = object[0]; // should internally be stored as the desired degree 0..360
                  const s = object[1]; // Should internally be stored as the percent already 0..100
                  const l = object[2]; // Should internally be stored as the percent already 0..100
                  const h2 = object[3];
                  const s2 = object[4];
                  const l2 = object[5];

                  var lpcnt = l; // / 2.55;
                  if (lpcnt > 100) lpcnt = 100;
                  var spcnt = s; // / 2.55;
                  if (spcnt > 100) spcnt = 100;
                  var l2pcnt = l2; // / 2.55;
                  if (l2pcnt > 100) l2pcnt = 100;
                  var s2pcnt = s2; // / 2.55;
                  if (s2pcnt > 100) s2pcnt = 100;

                  const isRange =
                    !(h2 == 0 && l2 == 0 && s2 == 0) &&
                    !(h2 == h && l2 == l && s2 == s);

                  if (isRange)
                    return (
                      <div
                        key={i}
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        &nbsp; &nbsp;
                        <Tooltip
                          title={`hsl(${h},${s},${l}) - hsl(${h2},${s2},${l2})`}
                        >
                          <div>
                            <div
                              style={{
                                width: "40px",
                                height: "40px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                backgroundColor: `hsl(${h}, ${spcnt}%, ${lpcnt}%)`,
                                border: "2px solid #000",
                              }}
                            />

                            <div
                              style={{
                                width: "40px",
                                height: "40px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                backgroundColor: `hsl(${h2}, ${s2pcnt}%, ${l2pcnt}%)`,
                                border: "2px solid #000",
                              }}
                            />
                          </div>
                        </Tooltip>
                      </div>
                    );
                  else
                    return (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        &nbsp; &nbsp;
                        <Tooltip title={`hsl(${h},${s},${l})`}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "40px",
                              height: "40px",
                              marginLeft: "auto",
                              marginRight: "auto",
                              backgroundColor: `hsl(${h}, ${spcnt}%, ${lpcnt}%)`,
                              border: "2px solid #000",
                            }}
                          />
                        </Tooltip>
                      </div>
                    );
                })}
              </div>
            );
          },
        },
        {
          title: "Description",
          field: "description",
          editable: "onUpdate",
          editComponent: (tableData) => (
            <TextField
              value={tableData.rowData.description}
              onChange={(e) => tableData.onChange(e.target.value)}
              multiline={true}
              maxRows={5}
            />
          ),
        },
      ],
      object_detect: [
        { title: "Config Id", editable: "never", field: "searchConfigId" },
        {
          title: "Config Name",
          field: "searchConfigName",
          editable: "onUpdate",
        },

        {
          title: "Object Name",
          field: "objNameList",
          editable: "never",
          render: (rowData) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {rowData.objNameList.map((el, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && index % 10 === 0 && (
                      <div style={{ width: "100%" }} />
                    )}
                    <li style={{ paddingRight: "10px" }}>{` ${el}`}</li>
                  </React.Fragment>
                ))}
              </div>
            );
          },
        },
        {
          title: "Description",
          field: "description",
          editable: "onUpdate",
          editComponent: (tableData) => (
            <TextField
              value={tableData.rowData.description}
              onChange={(e) => tableData.onChange(e.target.value)}
              multiline={true}
              maxRows={5}
            />
          ),
        },
      ],
      anomaly_detect: [
        { title: "Config ID", editable: "never", field: "searchConfigId" },
        {
          title: "Config Name",
          field: "searchConfigName",
          editable: "onUpdate",
        },

        {
          title: "Detector Name",
          editable: "never",
          field: "detectorName",
        },
        {
          title: "Detector Parameters",
          editable: "never",
          field: "detectorParameters",
          render: (rowData) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {Object.keys(rowData.detectorParameters).map((el, index) => (
                  <React.Fragment key={index}>
                    <li
                      style={{ paddingRight: "20" }}
                    >{` ${el}: ${rowData.detectorParameters[el]}`}</li>
                    <br></br>
                  </React.Fragment>
                ))}
              </div>
            );
          },
        },
        {
          title: "Description",
          field: "description",
          editable: "onUpdate",
          editComponent: (tableData) => (
            <TextField
              value={tableData.rowData.description}
              onChange={(e) => tableData.onChange(e.target.value)}
              multiline={true}
              maxRows={5}
            />
          ),
        },
      ],
      thermal_rgb: [
        { title: "Config ID", editable: "never", field: "searchConfigId" },
        {
          title: "Config Name",
          field: "searchConfigName",
          editable: "onUpdate",
        },

        {
          title: "Object Name",
          editable: "never",
          field: "rgbList",
          render: (rowData) => {
            return (
              <div
                style={{
                  display: "flex",
                }}
              >
                <ul>
                  {rowData.rgbList.map((el) => (
                    <li>{`${el}`}</li>
                  ))}
                </ul>
              </div>
            );
          },
        },
        {
          title: "Description",
          field: "description",
          editable: "onUpdate",
          editComponent: (tableData) => (
            <TextField
              value={tableData.rowData.description}
              onChange={(e) => tableData.onChange(e.target.value)}
              multiline={true}
              maxRows={5}
            />
          ),
        },
      ],
      face_recog: [
        { title: "Config ID", editable: "never", field: "searchConfigId" },
        {
          title: "Config Name",
          field: "searchConfigName",
          editable: "onUpdate",
        },

        {
          title: "Object Name",
          editable: "never",
          field: "faceIdList",
          render: (rowData) => {
            return (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {rowData.faceUriList.map(function (object, i) {
                  return (
                    <div
                      key={i}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      &nbsp; &nbsp;
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "75px",
                          height: "75px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <img src={object} alt={object} />
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          },
        },
        {
          title: "Description",
          field: "description",
          editable: "onUpdate",
          editComponent: (tableData) => (
            <TextField
              value={tableData.rowData.description}
              onChange={(e) => tableData.onChange(e.target.value)}
              multiline={true}
              maxRows={5}
            />
          ),
        },
      ],
      radiometric: [
        { title: "Config ID", editable: "never", field: "searchConfigId" },
        {
          title: "Config Name",
          field: "searchConfigName",
          editable: "onUpdate",
        },
      ],
    });
    setData(searchConfigs);
    if (props?.modalView) {
      setVal({
        color_rgb: true,
        color_hsv: true,
        radiometric: true,
        face_recog: true,
        object_detect: true,
        anomaly_detect: true,
      });
    } else {
      setVal({
        color_rgb: true,
        color_hsv: true,
        thermal_rgb: true,
        radiometric: true,
        face_recog: true,
        object_detect: true,
        anomaly_detect: true,
      });
    }
  }, [searchConfigs]);
  return (
    <>
      <Box>
        {Object.keys(val).map((el, i) => {
          if (val[el]) {
            return (
              <div key={`el-${i}`}>
                <br></br>

                {data?.[el] ? (
                  <Table
                    key={`${i}`}
                    data={data[el]}
                    columns={columns[el]}
                    loading={props?.loading}
                    options={{
                      ...props?.tableOptions,
                    }}
                    onSelectionChange={props?.onSelectionChange}
                    onRowUpdate={props?.editable ? onUpdate : null}
                    editable={props?.editable}
                    deleteable={props?.deleteable}
                    onRowDelete={
                      props?.deleteable ? handleSearchConfigDeleteClick : null
                    }
                    recordType="search config"
                  />
                ) : (
                  !analysis_id && (
                    <div
                      sx={{
                        paddingBottom: 1,
                      }}
                    >
                      No {el} search configs found for case {case_id}
                    </div>
                  )
                )}
              </div>
            );
          }
        })}
      </Box>
    </>
  );
}
