import React from "react";
import "./PreviewResults.scss";

export const PreviewResults = ({ coordinates, preview }) => {
  return (
    <div className="preview-results">
      {preview && (
        <div className="preview-results__wrapper">
          {coordinates && (
            <div className="preview-results__coordinates">
              <p className="preview-results__coordinates-title">Results:</p>
              <p className="preview-results__coordinates-value">
                Left: {coordinates.top}
              </p>
              <p className="preview-results__coordinates-value">
                Right: {coordinates.top + coordinates.width}
              </p>
              <p className="preview-results__coordinates-value">
                Top: {coordinates.left}
              </p>
              <p className="preview-results__coordinates-value">
                Bottom: {coordinates.left + coordinates.height}
              </p>
            </div>
          )}
          <div className="preview-results__preview pixelated">
            <img src={preview} />
          </div>
        </div>
      )}
    </div>
  );
};
