import React, { useState, useContext, createRef, useRef } from "react";

import { API_URL } from "../API";
import { AppContext } from "../Context/appContext";
import { SearchConfigContext } from "../Context/searchConfigContext";
import { useParams } from "react-router-dom";

//MUI Icons
import CreateIcon from "@mui/icons-material/Create";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

//MUI Components
import {
  Box,
  Grid,
  Button,
  Paper,
  TextField,
  Checkbox,
  Typography,
  Container,
} from "@mui/material";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const options = [
  { label: "Grapes 🍇", value: "grapes", id: 1 },
  { label: "Mango 🥭", value: "mango", id: 2 },
  { label: "Strawberry 🍓", value: "strawberry", id: 3, disabled: true },
];

const replaceTestOptions = [
  { label: "Grapes 🍇", value: "grapes", id: 1 },
  { label: "Mango 🥭", value: "mango", id: 2 },
  { label: "Strawberry 🍓", value: "strawberry", id: 3 },
];

export default function DetectObjectsPalette() {
  const { authHeaders } = useContext(AppContext);
  const { getDetectableObjects, createDetectObjectsSearchConfig } =
    useContext(SearchConfigContext);

  const { case_id } = useParams();

  const [isLoading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);

  const searchNameInput = useRef();
  const searchDescriptionInput = useRef();

  const handleCheckboxChange = (event, index, object) => {
    if (event.target.checked) {
      // Add the index to checkedItems
      setCheckedItems([...checkedItems, object, index]);
      // setPaletteArray([...paletteArray, { rgb: rgbValue, hex: hexValue }]
    } else {
      // Remove the index from checkedItems
      setCheckedItems(
        checkedItems.filter((item) => item?.objId !== object?.objId)
      );
    }
  };

  const removeObjectFromList = (index) => {
    const updatedselected = [...selected];
    updatedselected.splice(index, 1);
    setSelected(updatedselected);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getDetectableObjects();
      console.log("getAnomalyDetectors response", response);
      setLoading(false);
      setOptions(response);
    } catch (error) {
      toast.error(error.message);
    }
    // fetch(API_URL, {
    //   method: "POST",
    //   headers: {
    //     ...authHeaders,
    //   },
    //   body: JSON.stringify({
    //     action: "get-detectable-objects",
    //   }),
    // })
    //   .then((response) => {
    //     return response.json();
    //   })
    //   .then((respData) => {
    //     console.log("respData", respData);
    //     if (respData.status != "success") {
    //       alert("detect object palette Failure getting obj list: ");
    //     } else {
    //       setOptions(respData.data);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("error", error);
    //   });
  };

  React.useEffect(() => {
    if (1 == 1) {
      fetchData();
    } else {
      setOptions(replaceTestOptions);
    }
  }, []);

  const navigate = useNavigate();

  const submitSelectedData = async () => {
    console.log("in submitSelectedData, with case_id =", case_id);
    if (selected.length > 0) {
      if (searchNameInput.current.value) {
        try {
          const response = await createDetectObjectsSearchConfig({
            cid: case_id,
            searchName: searchNameInput.current.value,
            description: searchDescriptionInput.current.value,
            objList: selected,
          });
          setOptions(response);
          navigate(`/cases/${case_id}/search-configs`);
        } catch (error) {
          toast.error(error.message);
        }
      } else {
        toast.warning("Name is required.");
      }
    } else {
      toast.warning("Select atleast one item.");
    }

    // fetch(API_URL, {
    //   method: "POST",
    //   headers: {
    //     ...authHeaders,
    //   },
    //   body: JSON.stringify({
    //     action: "create-detect-objects-search-config",
    //   }),
    // })
    //   .then((response) => {
    //     return response.json();
    //   })
    //   .then((respData) => {
    //     console.log("respData", respData);
    //     if (respData.status != "success") {
    //       alert("detect object submit Failure saving obj list: " + selected);
    //     } else {
    //       setOptions(respData.data);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("error", error);
    //   });
  };

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "93vh",
        overflow: "auto",
        paddingX: 10,
        paddingTop: 10,
      }}
    >
      <Grid container spacing={4} sx={{ height: "82vh" }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "82vh !Imporant",
            }}
          >
            <h1>Select Objects of Interest</h1>
            <MultiSelect
              options={options}
              value={selected}
              onChange={setSelected}
              labelledBy="Select"
              isLoading={isLoading}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper
            elevation={16}
            sx={{
              width: "100%",
              height: "82vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "1.5vw !Important",
            }}
          >
            <Container sx={{ padding: "0 !Important" }}>
              <TextField
                id="searchName"
                name="searchName"
                label="Search Config Name"
                placeholder="Search Config Name"
                inputRef={searchNameInput}
                fullWidth
              ></TextField>
              <TextField
                id="outlined-basic"
                label="Optional Description"
                inputRef={searchDescriptionInput}
                fullWidth
                multiline
                sx={{
                  marginTop: "1vw",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  maxHeight: "58vh",
                  marginTop: "1vw",
                  overflow: "auto",
                  scrollbarWidth: "auto",
                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                  },
                }}
              >
                <pre>
                  {selected.map((object, index) => (
                    <>
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "start",
                          paddingLeft: "2vw",
                          paddingRight: "2vw",
                          marginTop: "2px",
                          borderBottom: "2px solid black",
                        }}
                      >
                        <Box sx={{ marginRight: 2 }}>
                          <Checkbox
                            onChange={(event) =>
                              handleCheckboxChange(event, index, object)
                            }
                            checked={checkedItems.includes(index)}
                          />
                        </Box>

                        <Box sx={{ marginLeft: 3 }}>
                          <Typography>{object.value}</Typography>
                        </Box>
                        <Box
                          sx={{
                            marginLeft: "auto",
                            marginBottom: 2,
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <DeleteForeverIcon
                            sx={{ marginLeft: 3 }}
                            onClick={() => removeObjectFromList(index)}
                          />
                        </Box>
                      </Box>
                    </>
                    // <Box
                    //   sx={{
                    //     display: "flex",
                    //     borderBottom: "2px solid black",
                    //     maxWidth: 400,
                    //   }}
                    // >
                    //   <p>{object.value}</p>
                    // </Box>
                  ))}
                </pre>
              </Box>
            </Container>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                gap: 1,
              }}
            >
              <Button
                onClick={submitSelectedData}
                variant="contained"
                color="primary"
                sx={{
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "none",
                  },
                  maxHeight: 55,
                }}
              >
                Submit
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
