//REACT Imports
import React, { useEffect } from "react";

import { toast } from "react-toastify";
import { flushSync } from "react-dom";
// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { useParams } from "react-router-dom";

import { useContext, useState, useRef } from "react";
import { UserContext } from "../Context/userContext";
import { CaseContext } from "../Context/caseContext";
import { AnalysisContext } from "../Context/analysisContext";

import { UiContext } from "../Context/uiContext";
import { SearchConfigContext } from "../Context/searchConfigContext";

import ImageViewModal from "../Modals/imageViewModal";

//Project Components
import ZoomImageModal from "../Components/ZoomImageModal";
import Slidish from "slidish";
import "slidish/style.css";

//MUI COMPONENTS
import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Tooltip,
  Button,
} from "@mui/material";

import ReplayIcon from "@mui/icons-material/Replay";
import InfoIcon from "@mui/icons-material/Info";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import SearchConfigModal from "../Modals/searchConfigModal";
import ImageComparisonModal from "../Modals/ImageComparisonModal";
import { MainLayout } from "../Layouts/MainLayout";
import TaskStatusModal from "../Modals/TaskStatusModal";
import HitsViewModal from "../Modals/HitsViewModal";
import FramesTable from "./Frames/FramesTable";
import FrameDataTable from "./Frames/FrameDataTable";
import FrameDataImagesTable from "./Frames/FrameDataImagesTable";
import NotificationModal from "../Modals/notificationModal";

export default function AnalysisResultsPage() {
  const {
    handleSearchConfigModalOpen,
    searchConfigModalOpen,
    statusModalOpen,
    imageViewModalOpen,
    hitsViewModalOpen,
    notificationModalOpen,
    handleStatusModalOpen,
    imageComparisonModalOpen,
    handleImageComparisonModalOpen,
    handleImageComparisonModalClose,
  } = useContext(UiContext);
  const { setSearchConfigs } = useContext(SearchConfigContext);
  const { userCtx } = useContext(UserContext);
  const { sarCase, getCaseByID } = useContext(CaseContext);
  const {
    getAnalysisByID,
    analysisCtx,

    setAnalysisResults,
    setAnalysisCtx,

    getAnalysesByCase,
    currFrameData,
    currFrame,
    setCurrFrame,

    searchConfig,
    framesLoading,

    frameDataImages,
    setFrameDataImages,
    currframeDataImage,

    frameDataCount,
    frameDataImagesCount,
    framesDataLoading,
    framesDataImagesLoading,
    slidishRef,
    setFrameDataCount,
    setFrameDataImagesCount,
    updateAnalysisCtx,
    setCurrFrameData,
    setCurrFrameDataImage,
    setAnalyses,
    currImage,
    setCurrImage,
  } = useContext(AnalysisContext);

  const [loading, setLoading] = useState(false);

  const { case_id, analysis_id } = useParams();

  const [framesExpanded, setFramesExpanded] = React.useState(true);
  const handleFramesExpandedChange = (panel) => (event, isExpanded) => {
    setFramesExpanded(!framesExpanded);
  };

  const [isZoomed, setIsZoomed] = React.useState(false);
  const [zoomImageFilePath, setZoomImageFilePth] = React.useState();

  // Analysis Data results will look like:
  // [ { "analysisId": ##, "flights": [ {flight}, {flight}, ... ] } ]
  // flight ~= { "flightId": ##, "frames": [ {frame}, {frame}, ... ] }
  // frame ~= { "frameId": ##, ......, "frameData": [ {fd}, {fd}, ... ]
  // fd ~= { "frameDataId": ##, ......, "frameDataImages": [ {fdi}, {fdi}, ... ]
  // fdi ~= { "frameDataImageId": ##, .... }
  // IMAGES will have to be prefixed with "https://find911-data.s3.amazonaws.com/" (or whatever bucket we evolve to)
  // frame data images are separated by frame datas, but to the user, should be considered part of the same frame (for now, I think, subject to Ehti's approval/suggestions)

  const [selectedRow, setSelectedRow] = useState(null);
  function selectCurrframeDataImageIndex(
    previousIndex,
    currentIndex,
    slidesCount
  ) {
    setSelectedRow(frameDataImages[currentIndex]["frameDataImageId"]);
  }

  const fetchCaseData = async () => {
    return getCaseByID(case_id);
  };

  const fetchAnalysis = async () => {
    return getAnalysisByID(analysis_id);
  };

  const reloadData = async () => {
    flushSync(() => {
      updateAnalysisCtx({});
      setFrameDataCount(false);
      setFrameDataImagesCount(false);
      setFrameDataImages([]);
      setCurrFrame({});
    });
    await fetchAnalysis();
  };

  const fetchContextOnLoad = async () => {
    setLoading(true);
    if (!sarCase.sarCaseId) await fetchCaseData();
    await fetchAnalysis();
    setLoading(false);
  };

  React.useEffect(() => {
    setSearchConfigs(searchConfig);
    return () => {
      setSearchConfigs([]);
    };
  }, [searchConfig]);

  React.useEffect(() => {
    fetchContextOnLoad();
    return () => {
      flushSync(() => {
        updateAnalysisCtx({});
        setFrameDataCount(false);
        setFrameDataImagesCount(false);
        setFrameDataImages([]);
        setCurrFrame({});
        setCurrFrameData({});
        setCurrFrameDataImage({});
      });
      setAnalysisResults([]);
    };
  }, []);

  // const flagCurrFrameDataImage = () => {
  //   if (currFrameData.length !== 0) {
  //     let dupFrameDataImages = frameDataImages;
  //     dupFrameDataImages[frameDataIndex]["is_flagged"] =
  //       !dupFrameDataImages[frameDataIndex]["is_flagged"];
  //     setFrameDataImages(dupFrameDataImages);
  //     let dupCurrFrameData = JSON.parse(JSON.stringify(currFrameData));
  //     dupCurrFrameData[0]["is_flagged"] =
  //       dupFrameDataImages[frameDataIndex]["is_flagged"];
  //     setCurrFrameData(dupCurrFrameData);
  //     console.log(currFrameData[0]["frameDataImages"][0]["frameDataImageId"]);
  //     // flagFrameDataImage(currFrameData[0]["frameDataImages"][0]["frameDataImageId"])
  //   } else {
  //     toast.warning("Select image by clicking on image thumbnail");
  //   }
  // };

  // const archiveCurrFrameDataImage = () => {
  //   if (currFrameData.length !== 0) {
  //     let dupFrameDataImages = frameDataImages;
  //     dupFrameDataImages[frameDataIndex]["is_archived"] =
  //       !dupFrameDataImages[frameDataIndex]["is_archived"];
  //     setFrameDataImages(dupFrameDataImages);
  //     let dupCurrFrameData = JSON.parse(JSON.stringify(currFrameData));
  //     dupCurrFrameData[0]["is_archived"] =
  //       dupFrameDataImages[frameDataIndex]["is_archived"];
  //     setCurrFrameData(dupCurrFrameData);
  //     console.log(currFrameData[0]["frameDataImages"][0]["frameDataImageId"]);
  //     // flagFrameDataImage(currFrameData[0]["frameDataImages"][0]["frameDataImageId"])
  //   } else {
  //     toast.warning("Select image by clicking on image thumbnail");
  //   }
  // };

  // const flagCurrFrame = (frame) => {
  //   if (currFrame) {
  //     let dupFrameDataImages = frameImages;
  //     console.log("dupFrameDataImages", dupFrameDataImages);
  //     console.log("frameIndex", frameIndex);
  //     console.log(
  //       "dupFrameDataImages[frameIndex]",
  //       dupFrameDataImages[frameIndex]
  //     );

  //     dupFrameDataImages[frameIndex]["is_flagged"] =
  //       !dupFrameDataImages[frameIndex]["is_flagged"];
  //     setFrameImages(dupFrameDataImages);

  //     let dupCurrFrame = JSON.parse(JSON.stringify(currFrame));
  //     console.log("dupCurrFrame", dupCurrFrame);
  //     console.log("dupCurrFrame[0]flag", dupCurrFrame[0]["is_flagged"]);
  //     dupCurrFrame[0]["is_flagged"] =
  //       dupFrameDataImages[frameIndex]["is_flagged"];
  //     setCurrFrame(dupCurrFrame);
  //     flagFrame(frame.frameId);
  //   } else {
  //     toast.warning("Click on Image you want to anaylyse");
  //   }
  //   // flagFrame(frame_id);
  //   // setIsFlagged(!currFrame.is_flagged);
  //   // currFrame.is_flagged = !currFrame.is_flagged;
  // };

  // const archiveCurrFrame = (frame) => {
  //   if (currFrame) {
  //     let dupFrameDataImages = frameImages;
  //     console.log("dupFrameDataImages", dupFrameDataImages);
  //     console.log("frameIndex", frameIndex);
  //     console.log(
  //       "dupFrameDataImages[frameIndex]",
  //       dupFrameDataImages[frameIndex]
  //     );

  //     dupFrameDataImages[frameIndex]["is_archived"] =
  //       !dupFrameDataImages[frameIndex]["is_archived"];
  //     setFrameImages(dupFrameDataImages);

  //     let dupCurrFrame = JSON.parse(JSON.stringify(currFrame));
  //     console.log("dupCurrFrame", dupCurrFrame);
  //     console.log("dupCurrFrame[0]flag", dupCurrFrame[0]["is_archived"]);
  //     dupCurrFrame[0]["is_archived"] =
  //       dupFrameDataImages[frameIndex]["is_archived"];
  //     setCurrFrame(dupCurrFrame);
  //     archiveFrame(frame.frameId);
  //   } else {
  //     toast.warning("Click on Image you want to anaylyse");
  //   }
  // };

  const onClickZoom = (currImagePath) => {
    setIsZoomed(!isZoomed);
    setZoomImageFilePth(currImagePath);
  };

  const handleInfoClick = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        flushSync(() => {
          setAnalyses([]);
          // setAnalysisCtx({});
        });
        await getAnalysisByID(analysis_id);
        // await getAnalysesByCase(case_id);
        showStatusModal();
        resolve(true);
      } catch (error) {
        toast.error(error.message);
        reject(error);
      }
    });
  };

  const showStatusModal = () => {
    handleStatusModalOpen();
  };

  const showImageComparisonModal = () => {
    handleImageComparisonModalOpen();
  };

  return (
    <MainLayout title={`Analysis results ${analysisCtx.analysisName}`}>
      {statusModalOpen && (
        <TaskStatusModal
          todo_count={analysisCtx?.analysis_todo_count}
          done_count={analysisCtx?.analysis_done_count}
          percent={analysisCtx?.analysis_percent}
          clock_ms_used={analysisCtx?.clock_ms_used}
          estRemaining={analysisCtx?.estRemaining}
          analysis_attempted_count={analysisCtx?.analysis_attempted_count}
          analysis_errors={analysisCtx?.analysis_errors}
        />
      )}
      <ZoomImageModal
        zoomed={isZoomed}
        filePath={zoomImageFilePath}
        onClose={() => onClickZoom()}
      />

      {searchConfigModalOpen && <SearchConfigModal readOnly={true} />}
      {imageComparisonModalOpen && frameDataImages.length > 0 && (
        <ImageComparisonModal frameDataImages={frameDataImages} />
      )}
      {hitsViewModalOpen && (
        <HitsViewModal activeFrameDataImage={currframeDataImage} />
      )}
      {imageViewModalOpen && (
        <ImageViewModal
          image={currImage?.filepath.replace(/\.(tiff|tif)$/i, ".jpg")}
        />
      )}

      {notificationModalOpen && (
        <NotificationModal
          activeFrameDataImage={{ ...currframeDataImage, ...currFrame }}
        />
      )}

      <Grid container spacing={2} xs={12}>
        <Grid item spacing={2} xs={12}>
          <Box
            sx={{
              marginBottom: "5vh",
            }}
          >
            {analysisCtx?.analysisName && (
              <Box style={{ textAlign: "left" }}>
                <Typography variant="h4">Analysis Info</Typography>
                <Typography>
                  {" "}
                  <strong>Name:</strong> {analysisCtx?.analysisName}
                </Typography>
                <Typography>
                  {" "}
                  <strong>Description:</strong> {analysisCtx?.description}
                </Typography>
                <Typography>
                  {" "}
                  <strong>Tags:</strong> {analysisCtx?.matchTag}
                </Typography>
              </Box>
            )}
            <Accordion
              expanded={framesExpanded}
              onChange={handleFramesExpandedChange()}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ textDecoration: "underline" }}>
                  <strong> Frames </strong>
                </Typography>

                <div
                  style={{
                    textAlign: "right",
                    paddingRight: "5vh",
                  }}
                >
                  <Tooltip title="Search config context">
                    <InfoIcon
                      // fontSize="large"
                      onClick={(e) => {
                        e?.stopPropagation();
                        handleSearchConfigModalOpen();
                      }}
                    />
                  </Tooltip>
                </div>
                {/* <Tooltip title="Reload data">
                  <ReplayIcon
                    onClick={(e) => {
                      e?.stopPropagation();
                      reloadData();
                    }}
                  />
                </Tooltip> */}
                {/* <Tooltip title="Analysis status">
                  <InfoIcon
                    // fontSize="large"
                    onClick={(e) => {
                      e?.stopPropagation();
                      handleInfoClick();
                    }}
                  />
                </Tooltip> */}
              </AccordionSummary>
              <AccordionDetails>
                {analysisCtx.analysisId && (
                  <FramesTable
                    reloadData={reloadData}
                    handleInfoClick={handleInfoClick}
                    loading={framesLoading}
                    options={{
                      toolbar: true,
                      sorting: false,
                      draggable: false,
                      grouping: false,
                      search: false,
                      paging: true,
                      selection: false,
                      maxBodyHeight: "500px",
                      pageSize: 10,
                      pageSizeOptions: [10, 25, 50, 100],
                      actionsCellStyle: { zIndex: "0", position: "relative" },
                      headerStyle: { position: "sticky", top: 0, zIndex: 100 },
                    }}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box
            sx={{
              marginBottom: "5vh",
            }}
          >
            {frameDataCount > 0 && (
              <FrameDataTable
                title="Frame Data"
                loading={framesDataLoading}
                options={{
                  toolbar: true,
                  sorting: false,
                  draggable: false,
                  grouping: false,
                  search: true,
                  paging: true,
                  selection: false,
                  maxBodyHeight: "500px",
                  pageSize: 10,
                  pageSizeOptions: [10, 25, 50, 100],
                  actionsCellStyle: { zIndex: "0", position: "relative" },
                  headerStyle: { position: "sticky", top: 0, zIndex: 100 },
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid>
        {frameDataImages?.length > 0 && (
          <Paper
            elevation={18}
            height
            sx={{
              height: 660,
            }}
          >
            {/* <Typography>
                  <FlagIcon
                    color="secondary"
                    onClick={flagCurrFrameDataImage}
                  />
                  <Inventory2Icon
                    color="warning"
                    onClick={archiveCurrFrameDataImage}
                  />
                  <ChangeCircleIcon color="info" />
                </Typography> */}
            <Slidish
              options={{
                height: 640,
              }}
              layout={{
                slides: true,
                thumbs: true,
                // nextButton: false,
                // previousButton: false,
                playButton: false,
                // fullscreenButton: false,
                // fullscreenCloseButton: false,
                indexIndicator: false,
                // indicators: false,
                // progressBar: false,
              }}
              slideshow={{ autoplay: false }}
              handlers={{
                onSlideChange: selectCurrframeDataImageIndex,
              }}
              ref={slidishRef}
            >
              {frameDataImages
                .map((frameData, i) => (
                  <img
                    key={i}
                    src={frameData["filepath"]}
                    alt={frameData["description"]}
                  />
                ))
                .filter((el) => el !== undefined)}
            </Slidish>
          </Paper>
        )}

        <Box
          sx={{
            marginBottom: "5vh",
            marginTop: "5vh",
          }}
        >
          {frameDataImagesCount > 0 && (
            <>
              <Button
                onClick={(e) => {
                  e?.stopPropagation();
                  showImageComparisonModal();
                }}
              >
                Comapare Images
              </Button>
              <FrameDataImagesTable
                loading={framesDataImagesLoading}
                title="Frame Data Images Info"
                options={{
                  toolbar: true,
                  sorting: false,
                  draggable: false,
                  grouping: false,
                  search: true,
                  paging: true,
                  selection: false,
                  maxBodyHeight: "700px",
                  pageSize: 25,
                  pageSizeOptions: [10, 25, 50, 100],
                  actionsCellStyle: { zIndex: "0", position: "relative" },
                  headerStyle: { position: "sticky", top: 0, zIndex: 100 },
                  tableLayout: "auto",
                  rowStyle: (rowData) => ({
                    backgroundColor:
                      selectedRow === rowData.frameDataImageId
                        ? "#EEE"
                        : "#FFF",
                  }),
                }}
              />
            </>
          )}
        </Box>
      </Grid>
    </MainLayout>
  );
}
