import React, { useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import {
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Stack,
  Typography,
  Input,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { UserContext } from "../Context/userContext";
import { CaseContext } from "../Context/caseContext";
import { MainLayout } from "../Layouts/MainLayout";
import { toast } from "react-toastify";

export default function CaseCreatePage() {
  const navigate = useNavigate();
  const { sarCase, createCase } = useContext(CaseContext);
  const { userCtx, fetchWorkerDetails } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  //form submit fields
  const agencyNameInput = useRef();
  const caseNameInput = useRef();
  const leadNameInput = useRef();
  const leadPhoneInput = useRef();
  const leadEmailInput = useRef();
  const searchAreaNameInput = useRef();
  const caseDescriptionInput = useRef();

  const onSubmit = useCallback(
    () => async (event) => {
      setLoading(true);
      event.preventDefault();
      console.log("userCtx", userCtx);
      console.log("userCtx.workerOrgId", userCtx.workerOrgId);
      console.log("userCtx.workerId", userCtx.workerId);
      const data = {
        action: "create-case",
        gid: userCtx.workerOrgId,
        wid: userCtx.workerId,
        agencyName: agencyNameInput.current.value,
        caseName: caseNameInput.current.value,
        leadName: leadNameInput.current.value,
        leadPhone: leadPhoneInput.current.value,
        leadEmail: leadEmailInput.current.value,
        searchAreaName: searchAreaNameInput.current.value,
        caseDescription: caseDescriptionInput.current.value,
      };

      console.log(data);
      try {
        await createCase(data);
        navigate(`/cases`);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
      }
    },
    [userCtx]
  );

  React.useEffect(() => {
    console.log("case create string:");
    console.log(sarCase);
    console.log("createCase userCtx", userCtx.workerOrgId);
    // if(!userCtx.workerId){
    //   fetchWorkerDetails();
    // }
  }, [userCtx.cognitoId, userCtx.workerId, userCtx]);

  return (
    <MainLayout title="Case create">
      <form
        id="enroll"
        onSubmit={onSubmit()}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Paper elevation={16} sx={{ padding: 5, height: "82vh !Imporant" }}>
          <Stack direction="column" spacing={1}>
            <Typography variant="h2">Create Case</Typography>
            <TextField
              id="agencyName"
              name="agencyName"
              label="Lead Agency"
              placeholder="Lead Agency"
              inputRef={agencyNameInput}
            ></TextField>

            <TextField
              id="caseName"
              name="caseName"
              label="Case Name"
              placeholder="Case Name"
              inputRef={caseNameInput}
            ></TextField>

            <TextField
              id="leadName"
              name="leadName"
              label="Lead Name"
              placeholder="Lead Name"
              inputRef={leadNameInput}
            ></TextField>
            <TextField
              id="leadPhone"
              name="leadPhone"
              label="Lead Phone"
              placeholder="Lead Phone"
              inputRef={leadPhoneInput}
            ></TextField>
            <TextField
              id="leadEmail"
              name="leadEmail"
              label="Lead Email"
              placeholder="Lead Email"
              inputRef={leadEmailInput}
            ></TextField>

            <TextField
              id="searchAreaName"
              name="searchAreaName"
              label="Search Area Name"
              placeholder="Search Area Name"
              inputRef={searchAreaNameInput}
            ></TextField>

            <TextField
              multiline={true}
              rows={4}
              id="caseDescription"
              name="caseDescription"
              label="Brief Description"
              placeholder="Brief Description"
              inputRef={caseDescriptionInput}
            ></TextField>

            <Button disabled={loading} variant="contained" type="submit">
              Submit
            </Button>
          </Stack>
        </Paper>
      </form>
    </MainLayout>
  );
}
