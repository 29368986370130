import React, { forwardRef, useEffect, useState, useRef } from "react";

import MaterialTable from "@material-table/core";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTableContext } from "../Context/TableContext";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function Table({
  tableId = "default",
  title,
  columns,
  data: tableData,
  onRowUpdate,
  onRowDelete,
  onRowView,
  options = {
    toolbar: false,
    sorting: false,
    draggable: false,
    search: false,
    paging: false,
    rowStyle: {
      backgroundColor: "#EEE",
    },
  },
  actions = [],
  loading,
  onSelectionChange,
  editable = false,
  tableRef = null,
  key = null,
  deleteable = null,
  recordType = "record",
  dataQuery = false
}) {
  const [newTableData, setNewTableData] = useState([]);
  const { state, dispatch } = useTableContext();
  const tableState = state[tableId] || { page: 0, selectedRow: null };

  useEffect(() => {
    if(!dataQuery)
      setNewTableData([...tableData]);

  }, [tableData]);

  useEffect(() => {
    // Ensure the initial table state is stored in the context
    // console.log("state[tableId]:::",state[tableId]);
    // console.log("tableState:::",tableState);
    if (!state[tableId]) {
      dispatch({
        type: "UPDATE_TABLE_STATE",
        tableId,
        payload: tableState,
      });
    }
  }, [state, tableId, tableState, dispatch]);

  return (
    <MaterialTable
      title={title}
      options={{
        ...options,
        maxBodyHeight: "500px",
        actionsCellStyle: { position: "relative" },
        headerStyle: { position: "sticky", top: 0, zIndex: "1", backgroundColor: "white"  },
        initialPage: tableState.page,
        emptyRowsWhenPaging: false, // Prevent rendering empty rows
      }}
      icons={tableIcons}
      columns={columns}
      data={dataQuery ? tableData : newTableData}
      editable={{
        onRowUpdate: editable
          ? (newData, oldData) =>
              onRowUpdate(newData, oldData, newTableData, setNewTableData)
          : null,
        onRowDelete: deleteable ? (oldData) => onRowDelete(oldData) : null,
      }}
      isLoading={loading}
      onSelectionChange={onSelectionChange}
      onPageChange={(page) =>
        dispatch({
          type: "UPDATE_TABLE_STATE",
          tableId,
          payload: { page },
        })
      }
      onRowClick={(event, rowData) =>
        dispatch({
          type: "UPDATE_TABLE_STATE",
          tableId,
          payload: { selectedRow: rowData },
        })
      }
      tableRef={tableRef}
      key={key}
      actions={[
        ...actions,
        onRowView
          ? {
              icon: VisibilityIcon,
              tooltip: "View",
              onClick: (event, rowData) => onRowView(rowData),
            }
          : null,
      ]}
      localization={{
        body: {
          editRow: {
            deleteText: `Are you sure delete this ${recordType}? (Data will be permanently deleted)`,
          },
          editTooltip: `Edit ${recordType}`,
        },
        toolbar: {
          searchPlaceholder: `Search ${recordType}`,
        },
      }}
    />
  );
}
